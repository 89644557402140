import { withLayout } from '@/layout/Layout';
import Image from 'next/image';
import { ButtonLink } from '@/components/buttons/ButtonLink';
import { useTranslation } from 'next-i18next';
import { Section } from '@/components/common/Section';
import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useLocaleHook, useMediaQueryWidthPicker } from '@/hooks';
import {
  FooterMenuLocationEnum,
  FOOTER_DATA_QUERY,
  FOOTER_DATA_QUERY_EN,
} from '@/data/footerDataQuery';
import { footerDataTransformer } from '@/utils/footerDataTransformer';
import { getApolloClient } from '@/utils/apollo-client';
import { ALL_MENU_QUERY, MenuLocationEnum } from '@/data/allMenuQuery';
import { flatListToHierarchical } from '@/utils/flatListToHierarchical';
import { transformMenus } from '@/utils/transformMenus';
import { useEffect, useState } from 'react';

const NotFound = () => {
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const { t } = useTranslation('common');
  const { screenWidth } = useMediaQueryWidthPicker();
  const locale = useLocaleHook();
  const isUk = locale === 'uk';

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <>
      {isMounted && (
        <Section className="!lg:pt-[148px] !py-32 text-center md:py-25">
          <div className="flex flex-col gap-10">
            <Image
              src="/images/error-page.svg"
              alt="Page not found"
              className="mx-auto h-auto max-w-[460px]"
              width={screenWidth <= 500 ? screenWidth - 40 : 460}
              height={screenWidth <= 500 ? (screenWidth - 40) / 2.25 : 205}
            />
            <p className="mb-4 mt-8 text-xl">{t('errorPageNotice')}</p>
            <ButtonLink
              href="/"
              isInnerLink
              className="mx-auto"
              aria-label={
                isUk ? 'Перейти на головну сторінку' : 'Go to the main page'
              }
            >
              {t('errorPageLink')}
            </ButtonLink>
          </div>
        </Section>
      )}
    </>
  );
};

export default withLayout(NotFound);

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const client = getApolloClient();

  const location =
    locale == 'uk'
      ? MenuLocationEnum.PRIMARY_NAVIGATION
      : MenuLocationEnum.PRIMARY_NAVIGATION___EN;
  const {
    data: { menuItems },
  } = await client.query({
    query: ALL_MENU_QUERY,
    variables: {
      location,
    },
  });

  const flatList = flatListToHierarchical(menuItems.nodes);
  const menus = transformMenus(flatList);

  const footerLocation =
    locale == 'uk'
      ? FooterMenuLocationEnum.FOOTER_MENU
      : FooterMenuLocationEnum.FOOTER_MENU___EN;

  const { data: footerMenuItems } =
    locale == 'uk'
      ? await client.query({
          query: FOOTER_DATA_QUERY,
          variables: {
            location: footerLocation,
          },
        })
      : await client.query({
          query: FOOTER_DATA_QUERY_EN,
          variables: {
            location: footerLocation,
          },
        });
  const footerData = footerDataTransformer(footerMenuItems);

  return {
    props: {
      menus,
      footerData,
      ...(await serverSideTranslations(locale as string, ['common'])),
    },
  };
};
